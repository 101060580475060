import React, { useEffect, useState } from "react";
import ServiceCard from "../ServiceCard/ServiceCard";

const MyServices = () => {
   const [services, setServices] = useState([]);
   useEffect(() => {
      fetch("MyServices.json")
         .then((res) => res.json())
         .then((data) => setServices(data));
   }, []);
   return (
      <section id="services" className="max-w-[1350px] w-[95%] mx-auto my-[50px]">
         <h1 className="text-4xl text-center font-extrabold mb-2">What I Do</h1>
         <hr className="w-[90px] mx-auto border-t-[10px] border-t-sky-500 mb-7" />

         <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 my-12">
            {services.map((service) => (
               <ServiceCard key={service._id} service={service} />
            ))}
         </div>
      </section>
   );
};

export default MyServices;
