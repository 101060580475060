import React from "react";
import { FaRegCheckCircle } from "react-icons/fa";
import { PhotoView } from "react-photo-view";
import ProjectsLink from "../../sharedComponents/ProjectsLInk/ProjectsLink";

const ProjectModal = ({ currentProject, setCurrentProject }) => {
   const {
      title,
      description,
      photos,
      features,
      stackDetails,
      live_site,
      client_side,
      server_side,
   } = currentProject;
   const coverPhoto = photos[0].photo;
   return (
      <div>
         <input type="checkbox" id="projectModal" className="modal-toggle" />
         <label htmlFor="projectModal" className="modal cursor-pointer">
            <label className="modal-box max-w-[1024px] w-[90%] relative" htmlFor="">
               <img src={coverPhoto} alt="" className="mb-4" />
               <h3 className="text-3xl text-primary font-bold">{title}</h3>
               <p className="my-3 text-lg text-justify">{description}</p>

               <h4 className="text-xl font-bold underline my-3">Top Features:</h4>
               {features.map((feature, index) => (
                  <div
                     key={index}
                     className="text-lg flex gap-2 lg:gap-1 items-center justify-start mb-2"
                  >
                     <FaRegCheckCircle className="text-xl text-sky-400 w-[5%] h-[5]" />

                     <p className="w-[95%] m-0 p-0 leading-tight text-justify">{feature}</p>
                  </div>
               ))}

               <h4 className="text-xl font-bold underline my-3">Stack Details:</h4>
               {stackDetails.map((stack, index) => (
                  <div
                     key={index}
                     className="text-lg flex gap-2 lg:gap-1 items-center justify-start mb-2"
                  >
                     <FaRegCheckCircle className="text-xl text-sky-400 w-[5%] h-[5]" />

                     <p className="w-[95%] m-0 p-0 leading-tight text-justify">{stack}</p>
                  </div>
               ))}

               <h4 className="text-xl font-bold underline my-3">Quick Links:</h4>
               <div className="w-full md:w-[60%] lg:w-[40%]">
                  <ProjectsLink
                     live_site={live_site}
                     client_side={client_side}
                     server_side={server_side}
                  />
               </div>

               <h4 className="text-xl font-bold underline my-3">Site Pages:</h4>
               <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                  {photos.map((photo, index) => (
                     <PhotoView key={index} src={photo.photo}>
                        <img src={photo.photo} alt="" />
                     </PhotoView>
                  ))}
               </div>
            </label>
         </label>
      </div>
   );
};

export default ProjectModal;
