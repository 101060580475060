import React from "react";

const Footer = () => {
   const currentYear = new Date().getFullYear();

   return (
      <footer className="text-center text-sm font-semibold bg-base-300 py-[40px]">
         <p>
            Copyright © {currentYear}. All rights reserved by{" "}
            <span className="text-sky-400">Kazi Rahat Rezwan</span>
         </p>
      </footer>
   );
};

export default Footer;
