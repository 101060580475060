import React from "react";
import { BsGlobe, BsServer } from "react-icons/bs";
import { HiOutlineDesktopComputer } from "react-icons/hi";

const ProjectsLink = ({ live_site, client_side, server_side }) => {
   return (
      <div className="flex items-center justify-evenly gap-2 mt-2">
         <a
            href={live_site}
            target="_blank"
            rel="noreferrer"
            className="border rounded-md border-sky-400 text-sky-400 flex items-center gap-2 p-1 text-sm hover:border-white hover:text-white tooltip-bottom"
            data-tip="Live Site"
         >
            <BsGlobe className="w-[14px] h-[14px]" /> <p className="hidden md:block">Live Site</p>
         </a>
         <div className="divider divider-horizontal m-0"></div>
         <a
            href={client_side}
            target="_blank"
            rel="noreferrer"
            className="border rounded-md border-sky-400 text-sky-400 flex items-center gap-2 p-1 text-sm hover:border-white hover:text-white tooltip-bottom"
            data-tip="Client Side"
         >
            <HiOutlineDesktopComputer className="w-[14px] h-[14px]" />{" "}
            <p className="hidden md:block">Client Side</p>
         </a>
         <div className="divider divider-horizontal m-0"></div>
         <a
            href={server_side}
            target="_blank"
            rel="noreferrer"
            className="border rounded-md border-sky-400 text-sky-400 flex items-center gap-2 p-1 text-sm hover:border-white hover:text-white tooltip-bottom"
            data-tip="Server Side"
         >
            <BsServer className="w-[14px] h-[14px]" />{" "}
            <p className="hidden md:block">Server Side</p>
         </a>
      </div>
   );
};

export default ProjectsLink;
