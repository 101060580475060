import React, { useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import logo from '../../KR Logo.png';

const menuItems = [
   { id: '01', menu: 'About', link: '/#about' },
   { id: '01', menu: 'Experience', link: '/#experience' },
   { id: '02', menu: 'My Services', link: '/#services' },
   { id: '03', menu: 'Skills', link: '/#skills' },
   { id: '04', menu: 'Projects', link: '/#projects' },
   { id: '05', menu: 'Contact Me', link: '/#contact' },
];

const Navbar = () => {
   const [show, setShow] = useState(false);
   return (
      <div>
         {/* Navbar Normal */}
         <nav className='flex justify-between items-center py-7 max-w-[1350px] w-[95%] mx-auto'>
            <div className='w-[50%]'>
               {/* <img src={logo} alt="" /> */}
               <HashLink
                  className='text-2xl text-white font-extrabold flex items-center justify-start gap-3'
                  to='/'
               >
                  <img src={logo} alt='' className='w-[20%] md:w-[12%] lg:w-[9%]' />
                  <p>
                     Kazi<span className='text-sky-400'>Rahat</span>
                  </p>
               </HashLink>
            </div>
            <div className='hidden lg:block flex-grow'>
               <ul className='flex justify-end gap-10'>
                  {menuItems.map((menu) => (
                     <li key={menu.id}>
                        <HashLink to={menu.link} className='text-lg text-white'>
                           {menu.menu}
                        </HashLink>
                     </li>
                  ))}
               </ul>
            </div>
            <div className='dropdown dropdown-end lg:hidden'>
               <label
                  tabIndex={0}
                  className='btn bg-base-200 border border-sky-400 swap swap-rotate'
               >
                  <input type='checkbox' onClick={() => setShow(!show)} />
                  <svg
                     className='swap-off fill-current text-sky-400'
                     xmlns='http://www.w3.org/2000/svg'
                     width='25'
                     height='25'
                     viewBox='0 0 512 512'
                  >
                     <path d='M64,384H448V341.33H64Zm0-106.67H448V234.67H64ZM64,128v42.67H448V128Z' />
                  </svg>

                  <svg
                     className='swap-on fill-current text-sky-400'
                     xmlns='http://www.w3.org/2000/svg'
                     width='25'
                     height='25'
                     viewBox='0 0 512 512'
                  >
                     <polygon points='400 145.49 366.51 112 256 222.51 145.49 112 112 145.49 222.51 256 112 366.51 145.49 400 256 289.49 366.51 400 400 366.51 289.49 256 400 145.49' />
                  </svg>
               </label>
               <ul
                  tabIndex={0}
                  className={
                     show
                        ? 'dropdown-content menu p-2 shadow bg-base-200 rounded-box w-52 border border-sky-400 z-10 mt-2'
                        : 'hidden'
                  }
               >
                  {menuItems.map((menu) => (
                     <li key={menu.id}>
                        <HashLink to={menu.link}>{menu.menu}</HashLink>
                     </li>
                  ))}
               </ul>
            </div>
         </nav>
      </div>
   );
};

export default Navbar;
