import React, { useEffect, useState } from "react";
import ProjectCard from "../ProjectCard/ProjectCard";
import ProjectModal from "../ProjectModal/ProjectModal";

const Projects = () => {
   const [projects, setProjects] = useState([]);
   const [currentProject, setCurrentProject] = useState(null);
   useEffect(() => {
      fetch("projects.json")
         .then((res) => res.json())
         .then((data) => setProjects(data));
   }, []);
   return (
      <section id="projects" className="bg-base-300">
         <div className="max-w-[1350px] w-[95%] mx-auto py-[60px]">
            <h1 className="text-4xl text-center font-extrabold mb-2">My Projects</h1>
            <hr className="w-[180px] mx-auto border-t-[10px] border-t-sky-500 mb-12" />

            {/* Projects card */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
               {projects.map((project) => (
                  <ProjectCard
                     key={project._id}
                     project={project}
                     setCurrentProject={setCurrentProject}
                  />
               ))}
            </div>

            {currentProject && (
               <ProjectModal
                  currentProject={currentProject}
                  setCurrentProject={setCurrentProject}
               />
            )}
         </div>
      </section>
   );
};

export default Projects;
