import React from "react";
import { BsFillTelephoneFill } from "react-icons/bs";
import { FaGithub, FaLinkedin, FaTwitter } from "react-icons/fa";
import { HiLocationMarker, HiMail } from "react-icons/hi";
import ContactForm from "../ContactForm/ContactForm";

const Contact = () => {
   return (
      <section id="contact" className="my-[70px]">
         <div className="max-w-[1300px] w-[95%] mx-auto">
            <h1 className="text-4xl text-center font-extrabold mb-2">Contact Me</h1>
            <hr className="w-[100px] mx-auto border-t-[10px] border-t-sky-500 mb-16" />
            <div className="flex flex-col-reverse md:flex-row justify-start items-center gap-[70px]">
               <div className="border border-sky-500 rounded-md w-full lg:w-[40%] p-10 bg-base-200">
                  <h1 className="text-4xl font-extrabold mb-2">Contact Info</h1>
                  <hr className="w-[70px] border-t-[10px] border-t-sky-500 mb-5" />
                  <p className="text-lg text-justify mb-10">
                     Fill out the form and I will get back to you within 24 hours.
                  </p>

                  <p className="flex gap-2 items-center my-2 text-lg">
                     <BsFillTelephoneFill /> +880 1710722889
                  </p>
                  <p className="flex gap-2 items-center my-2 text-lg">
                     <HiMail /> kazi.rahat.rezwan889@gmail.com
                  </p>
                  <p className="flex gap-2 items-center my-2 text-lg">
                     <HiLocationMarker /> Bangladesh
                  </p>

                  <h4 className="text-lg text-center font-bold mt-16 mb-3">Follow Me</h4>
                  <div className="flex gap-2 justify-evenly">
                     <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://github.com/RahatRezwan"
                        className="text-md flex gap-2 items-center text-sky-400 border-2 border-sky-400 rounded-md px-3 py-1 tooltip tooltip-bottom"
                        data-tip="GitHub"
                     >
                        <FaGithub /> <p className="hidden md:block">Github</p>
                     </a>
                     <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.linkedin.com/in/kazi-rahat-rezwan/"
                        className="text-md flex gap-2 items-center text-sky-400 border-2 border-sky-400 rounded-md px-3 py-1 tooltip tooltip-bottom"
                        data-tip="Linkedin"
                     >
                        <FaLinkedin /> <p className="hidden md:block">LinkedIn</p>
                     </a>
                     <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://twitter.com/KaziRahatRezwan"
                        className="text-md flex gap-2 items-center text-sky-400 border-2 border-sky-400 rounded-md px-3 py-1 tooltip tooltip-bottom"
                        data-tip="Twitter"
                     >
                        <FaTwitter /> <p className="hidden md:block">Twitter</p>
                     </a>
                  </div>
               </div>

               <div className="w-full lg:w-[60%]">
                  <ContactForm />
               </div>
            </div>
         </div>
      </section>
   );
};

export default Contact;
