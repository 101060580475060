import { send } from "emailjs-com";
import React from "react";
import { toast } from "react-hot-toast";

const ContactForm = () => {
   const handleSubmit = (event) => {
      event.preventDefault();
      const form = event.target;
      const fullName = form.firstName.value + " " + form.lastName.value;
      const email = form.email.value;
      const mobile = form.mobile.value;
      const subject = form.subject.value;
      const message = form.message.value;

      const formData = {
         fullName,
         email,
         mobile,
         subject,
         message,
      };

      console.log(formData);
      send(
         process.env.REACT_APP_service_id,
         process.env.REACT_APP_template_id,
         formData,
         process.env.REACT_APP_user_id,
      )
         .then((response) => {
            console.log("SUCCESS!", response.status, response.text);
            toast.success("Message Send Successfully!!");
            form.reset();
         })
         .catch((err) => {
            console.log("FAILED...", err);
            toast.error("Sorry Something went wrong.");
         });
   };
   return (
      <form onSubmit={handleSubmit}>
         <input
            type="text"
            name="firstName"
            placeholder="First Name"
            className="input input-bordered input-info w-full mb-3 bg-base-300 "
         />

         <input
            type="text"
            name="lastName"
            placeholder="Last Name"
            className="input input-bordered input-info w-full mb-3 bg-base-300 "
         />

         <input
            type="email"
            name="email"
            placeholder="email@example.com"
            className="input input-bordered input-info w-full mb-3 bg-base-300 "
         />

         <input
            type="text"
            name="mobile"
            placeholder="Your Mobile No"
            className="input input-bordered input-info w-full mb-3 bg-base-300 "
         />

         <input
            type="text"
            name="subject"
            placeholder="subject"
            className="input input-bordered input-info w-full mb-3 bg-base-300 "
         />

         <textarea
            className="textarea textarea-info w-full mb-3 bg-base-300"
            name="message"
            placeholder="Write Your Message"
         ></textarea>

         <div className="w-[30%] mx-auto">
            <button
               type="submit"
               className="btn bg-sky-500 hover:bg-base-200 border border-sky-600 text-white w-full"
            >
               Submit
            </button>
         </div>
      </form>
   );
};

export default ContactForm;

// service_bqs30oo
