import React from "react";
import { FaArrowRight } from "react-icons/fa";
import ProjectsLink from "../../sharedComponents/ProjectsLInk/ProjectsLink";

const ProjectCard = ({ project, setCurrentProject }) => {
   const { title, excerpt, photos, live_site, client_side, server_side } = project;
   const coverPhoto = photos[0].photo;
   return (
      <div className="border border-sky-400 rounded-md bg-base-300 cursor-pointer">
         <div className="m-5 rounded-md overflow-hidden">
            <img src={coverPhoto} alt="" />
         </div>
         <div className="m-5">
            <h1 className="text-2xl font-bold text-primary">{title}</h1>
            <p className="text-justify mt-3">{excerpt}</p>
            <label
               htmlFor="projectModal"
               onClick={() => setCurrentProject(project)}
               className="text-justify mt-3 flex gap-3 items-center text-sky-400"
            >
               See Details <FaArrowRight />
            </label>
            <div className="divider"></div>
            <ProjectsLink
               live_site={live_site}
               client_side={client_side}
               server_side={server_side}
            />
         </div>
      </div>
   );
};

export default ProjectCard;
