import React from 'react';
import { About, HeroSection, MyServices, Navbar, Skills } from '../../components';
import Contact from '../../components/Contact/Contact';
import Footer from '../../components/Footer/Footer';
import Projects from '../../components/Projects/Projects';
import Experience from '../../components/Experience/Experience';

const Home = () => {
   return (
      <div>
         <Navbar />
         <HeroSection />
         <About />
         <Experience />
         <MyServices />
         <Skills />
         <Projects />
         <Contact />
         <Footer />
      </div>
   );
};

export default Home;
