import React from "react";

const ServiceCard = ({ service }) => {
   const { image, title, Description } = service;
   return (
      <>
         <div className="border border-sky-400 rounded-md bg-base-200 p-5 cursor-pointer hover:shadow hover:shadow-slate-300 hover:scale-105 hover:transition-transform">
            <img src={image} alt="" className="w-[80px] mx-auto mb-5" />
            <h1 className="text-2xl text-sky-400 font-bold mb-3 text-center">{title}</h1>
            <p className="text-center">{Description}</p>
         </div>
      </>
   );
};

export default ServiceCard;
