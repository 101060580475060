import React, { useEffect, useState } from "react";

const Skills = () => {
   const [skills, setSkills] = useState([]);
   useEffect(() => {
      fetch("allSkills.json")
         .then((res) => res.json())
         .then((data) => setSkills(data));
   }, []);
   return (
      <section id="skills" className="my-[80px]">
         <div className="max-w-[1350px] w-[95%] mx-auto">
            <h1 className="text-4xl text-center font-extrabold mb-2">Skills</h1>
            <hr className="w-[50px] mx-auto border-t-[10px] border-t-sky-500 mb-7" />
            <div className="flex flex-wrap justify-center items-center gap-6 mt-10">
               {skills.map((skill) => (
                  <div key={skill._id} className="w-[70px]">
                     <img
                        src={skill.image}
                        alt=""
                        className="w-[50px] h-[50px] mx-auto bg-white p-1 rounded"
                     />
                     <p className="text-center text-xs mt-1">{skill.name}</p>
                  </div>
               ))}
            </div>
         </div>
      </section>
   );
};

export default Skills;
