import React from 'react';

const Experience = () => {
   return (
      <section id='experience' className='max-w-[1350px] w-[95%] mx-auto my-[50px]'>
         <h1 className='text-4xl text-center font-extrabold mb-2'>Job Experience</h1>
         <hr className='w-[90px] mx-auto border-t-[10px] border-t-sky-500 mb-7' />

         {/* Experience TimeLine */}
         <ul className='timeline timeline-snap-icon max-md:timeline-compact timeline-vertical'>
            <li>
               <div className='timeline-middle'>
                  <svg
                     xmlns='http://www.w3.org/2000/svg'
                     viewBox='0 0 20 20'
                     fill='currentColor'
                     className='h-5 w-5 text-sky-500'
                  >
                     <path
                        fillRule='evenodd'
                        d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z'
                        clipRule='evenodd'
                     />
                  </svg>
               </div>
               <div className='timeline-start md:text-end mb-10'>
                  <time className='font-mono italic'>September, 2023 - Present</time>
                  <div className='text-lg font-black text-sky-500'>Junior Full Stack Developer</div>
                  <div className='text-md font-medium mb-2 text-sky-400'>As Sunnah Foundation</div>
                  I'm developing performant React applications using Next.js, Redux, and RTK Query,
                  ensuring optimized load times and seamless data handling. I'm crafting visually
                  appealing and accessible UIs with MUI, Ant Design, and Tailwind CSS, demonstrating
                  versatility in frontend styling. I'm collaborating effectively within a 3-person
                  development team, contributing to code reviews, Agile processes, and a positive
                  team dynamic.
               </div>
               <hr />
            </li>
            <li>
               <hr />
               <div className='timeline-middle'>
                  <svg
                     xmlns='http://www.w3.org/2000/svg'
                     viewBox='0 0 20 20'
                     fill='currentColor'
                     className='h-5 w-5 text-sky-500'
                  >
                     <path
                        fillRule='evenodd'
                        d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z'
                        clipRule='evenodd'
                     />
                  </svg>
               </div>
               <div className='timeline-end mb-10'>
                  <time className='font-mono italic'>June, 2023 - September, 2023</time>
                  <div className='text-lg font-black text-sky-500'>Junior Full Stack Developer</div>
                  <div className='text-md font-medium mb-2 text-sky-400'>
                     AIDE Business Solution
                  </div>
                  I focused on enhancing user experience by developing and adding new features to
                  our website. To streamline future updates, I built reusable components for later
                  integration. Additionally, I spearheaded the development of our company's ERP
                  system using a robust tech stack of React, Typescript, TailwindCSS, Node.js,
                  Express, and MongoDB. This project involved leading the backend development team,
                  ensuring we delivered a scalable and efficient ERP solution.
               </div>
               <hr />
            </li>

            <li>
               <hr />
               <div className='timeline-middle'>
                  <svg
                     xmlns='http://www.w3.org/2000/svg'
                     viewBox='0 0 20 20'
                     fill='currentColor'
                     className='h-5 w-5 text-sky-500'
                  >
                     <path
                        fillRule='evenodd'
                        d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z'
                        clipRule='evenodd'
                     />
                  </svg>
               </div>
               <div className='timeline-start md:text-end mb-10'>
                  <time className='font-mono italic'>January, 2023 - May, 2023</time>
                  <div className='text-lg font-black text-sky-500'>Intern Software Developer</div>
                  <div className='text-md font-medium mb-2 text-sky-400'>
                     AIDE Business Solution
                  </div>
                  I developed a comprehensive Admin Panel using ReactJS, TypeScript, Redux Toolkit,
                  and TailwindCSS to streamline internal processes. To support this, I created
                  robust APIs using NodeJS and ExpressJS, effectively storing data in MongoDB.
                  Throughout these projects, I actively learned and applied TypeScript, Redux, team
                  management principles, and effective GitHub collaboration techniques.
               </div>
            </li>
         </ul>
      </section>
   );
};

export default Experience;
